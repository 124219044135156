import { ProtocolService } from './protocol.service';
export function injectableProtocolFactory(token, name, loggers) {
  return {
    provide: token,
    useFactory: ps => {
      return ps.create({
        name,
        loggers
      });
    },
    deps: [ProtocolService]
  };
}