import { isUserRoleCheckValue, roleCheck, UserRole, UserRoleRank } from '../access/user-role';
import { AbilityUtils } from './ability.utils';
// when passing an AbilityOrRoleId[], it will be interpreted as an AND list.
export function simpleAbilityRequirementToStruct(requirement) {
  let check;
  if (Array.isArray(requirement)) check = {
    allow: [requirement]
  };else if (typeof requirement === 'object') check = requirement;else if (typeof requirement === 'string') check = {
    allow: [requirement]
  };else throw new Error('@AccessRestriction: Invalid Configuration: ' + JSON.stringify(requirement));
  return check;
}
export function simpleAbilityStructExtractActions(requirement) {
  const list = [];
  ['allow', 'except'].forEach(key => {
    if (!requirement[key]) return;
    const orList = Array.isArray(requirement[key]) ? requirement[key] : [requirement[key]];
    orList.forEach(data => {
      const andList = Array.isArray(data) ? data : [data];
      andList.forEach(abilityOrUserRole => {
        if (!isUserRoleCheckValue(abilityOrUserRole)) {
          list.push(abilityOrUserRole);
        }
      });
    });
  });
  return list;
}
export function assertValidAbilityActionsInSimpleAbilityRequirement(abilitySubjectsRegistry, requirement, origin) {
  const issues = checkValidAbilityActionsInSimpleAbilityRequirement(abilitySubjectsRegistry, requirement, origin);
  if (Array.isArray(issues)) {
    issues.forEach(info => console.error(info));
  }
}
export function checkValidAbilityActionsInSimpleAbilityRequirement(abilitySubjectsRegistry, requirement, origin) {
  const struct = simpleAbilityRequirementToStruct(requirement);
  const actions = simpleAbilityStructExtractActions(struct);
  return AbilityUtils.checkValidAbilityAction(abilitySubjectsRegistry, actions, origin);
}
// when passing an AbilityOrRoleId[] as requirement, it will be interpreted as an AND list.
export function simpleAbilityCheck(userRole, grantedAbilities, requirement, respectSuperuser = true, options = {}) {
  options.respectSuperuser = respectSuperuser;
  if (options.respectSuperuser && userRole === UserRole.SUPERUSER) return true;
  if (!requirement) return true;
  const check = simpleAbilityRequirementToStruct(requirement);
  const matchesAllow = check.allow ? fullfillsSimpleAbilityList(userRole, grantedAbilities, check.allow, options) : true;
  const matchesExcept = check.except ? fullfillsSimpleAbilityList(userRole, grantedAbilities, check.except, options) : false;
  return matchesAllow && !matchesExcept;
}
export function simpleAbilityCheckStrict(userRole, grantedAbilities, requirement, respectSuperuser = true) {
  return simpleAbilityCheck(userRole, grantedAbilities, requirement, respectSuperuser, {
    strict: true
  });
}
export function fullfillsSimpleAbilityList(userRole, grantedAbilities, required, options = {}) {
  if (options.respectSuperuser && userRole === UserRole.SUPERUSER) return true;
  // check if actually any access checks are required
  if (!required || required.length === 0) return true;
  assertNoRoleCheckConflict(required, options);
  // the check passes if any of the <or> entries in required evaluates to true
  return required.some(matchingDataWithinOrList => {
    const andList = Array.isArray(matchingDataWithinOrList) ? matchingDataWithinOrList : [matchingDataWithinOrList];
    return andList.every(abilityOrUserRole => {
      if (isUserRoleCheckValue(abilityOrUserRole)) {
        return roleCheck(abilityOrUserRole, userRole, options.respectSuperuser);
      } else {
        if (Array.isArray(grantedAbilities)) return grantedAbilities.includes(abilityOrUserRole);else return (grantedAbilities[abilityOrUserRole] || 0) > 0;
      }
    });
  });
}
/**
 * we need to ensure there are no conflicting role checks.
 * Examples:
 * [REGISTERED, ADMINISTRATOR] is a bad check, it should only be REGISTERED then.
 * [REGISTERED, !ADMINISTRATOR] is bad because it will match everything as one of the options will always be true.
 * [!REGISTERED, ADMINISTRATOR] should be allowed though.
 */
function assertNoRoleCheckConflict(required, options, listType = 'or') {
  if (!options.strict) return; // TODO: begin to validate and convert all simpleAbilityChecks to support strict mode
  const collectedRoles = required.reduce((collected, matchingDataWithinOrList) => {
    if (typeof matchingDataWithinOrList === 'string') {
      if (isUserRoleCheckValue(matchingDataWithinOrList)) collected.push(matchingDataWithinOrList);
    } else {
      if (matchingDataWithinOrList.length === 1 && isUserRoleCheckValue(matchingDataWithinOrList[1])) collected.push(matchingDataWithinOrList[1]);
    }
    return collected;
  }, []);
  if (listType === 'or' && collectedRoles.length > 2) {
    throw new Error('received invalid SimpleAbilityList: No more than 2 roles can be used in OR level. Received: ' + collectedRoles.join(','));
  }
  if (collectedRoles.length === 2) {
    const role1IsNegative = collectedRoles[0].startsWith('!');
    const role2IsNegative = collectedRoles[1].startsWith('!');
    if (role1IsNegative === role2IsNegative) throw new Error('received invalid SimpleAbilityList: When specifying 2 roles, exactly one must be a negative role check expression. Received: ' + collectedRoles.join(','));
    const negativeRoleIndex = role1IsNegative ? 0 : 1;
    const negativeRoleRank = UserRoleRank[collectedRoles[negativeRoleIndex].substring(1)];
    const positiveRoleRank = UserRoleRank[collectedRoles[negativeRoleIndex === 1 ? 0 : 1]];
    if (listType === 'or' && negativeRoleRank >= positiveRoleRank || listType === 'and' && negativeRoleRank <= positiveRoleRank) throw new Error('received invalid SimpleAbilityList: This combination of positive and negative roleCheck can never ' + (listType === 'or' ? 'fail' : 'pass') + ': ' + collectedRoles.join(','));
  }
  required.forEach(matchingDataWithinOrList => {
    if (Array.isArray(matchingDataWithinOrList)) assertNoRoleCheckConflict(matchingDataWithinOrList, options, 'and');
  });
}