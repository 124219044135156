import { ReplaySubject } from 'rxjs';
import { dayjs } from '../utils/dayjs';
export class ProtocolLogger {
  constructor(protocol) {
    this.protocol = protocol;
  }
  setEnabled(enabled) {
    this.enabled = enabled;
  }
}
export class ConsoleProtocolLogger extends ProtocolLogger {
  log(entry) {
    const dateString = dayjs(entry.date).format('HH:mm:ss');
    let loggedMessage = "[" + this.protocol.name + "] - " + dateString;
    if (entry.group) loggedMessage += ` [${entry.group}]`;
    loggedMessage += " " + entry.message;
    console.log(loggedMessage);
    if (entry.data) console.log('-- logged Data:', entry.data);
  }
}
export class AggregateProtocolLogger extends ProtocolLogger {
  constructor() {
    super(...arguments);
    this.all$ = new ReplaySubject();
  }
  log(entry) {
    this.all$.next(entry);
  }
}
// other loggers e.g. for file logging can be found in backend.