import { Subject } from 'rxjs';
/**
 * ProtocolDistributor acts as a router to one or many loggers.
 * TODO: rename to something more appropriate. ProtocolDistributor is just used as a helper service for distribution to loggers.
 */
export class ProtocolDistributor {
  constructor(registry, name, ProtocolLoggerCtors = []) {
    this.name = name;
    this.protocol$ = new Subject();
    this.groupIdMaxLength = false;
    this.loggers = [];
    this.displayName = name;
    registry.register(this);
    ProtocolLoggerCtors.forEach(Ctor => this.registerLogger(Ctor));
    this.protocol$.subscribe(entry => {
      this.loggers.forEach(logger => {
        if (logger.enabled) logger.log(entry);
      });
    });
    this.write('log created');
  }
  findLoggerOfType(loggerCtor) {
    const logger = this.loggers.find(l => l instanceof loggerCtor);
    return logger || null;
  }
  enableLogging(enable, loggerCtor) {
    if (loggerCtor) {
      const logger = this.findLoggerOfType(loggerCtor);
      logger.setEnabled(enable);
    } else {
      this.loggers.forEach(logger => {
        logger.setEnabled(enable);
      });
    }
  }
  write(message, data) {
    this.protocol$.next({
      message,
      group: null,
      data,
      date: new Date()
    });
  }
  setGroupIdMaxLength(length) {
    this.groupIdMaxLength = length;
  }
  writeToGroup(groupInfo, message, data) {
    let group = groupInfo ? groupInfo.toString() : null;
    if (this.groupIdMaxLength && group) group = group.substring(0, this.groupIdMaxLength);
    this.protocol$.next({
      message,
      group,
      data,
      date: new Date()
    });
  }
  registerLogger(Ctor) {
    this.loggers.push(new Ctor(this));
  }
  setDisplayName(name) {
    this.displayName = name;
  }
}