import { simpleAbilityCheck } from '@core/shared/ability';
import merge from 'deepmerge';
export var FeatureMenuItemHelper;
(function (FeatureMenuItemHelper) {
  function filterMenuItemsByAccess(menuItems, userRole, grantedAbilities) {
    return merge([], menuItems).filter(menuItem => {
      if (menuItem.simpleAbilityCheck) {
        const passed = simpleAbilityCheck(userRole, grantedAbilities, menuItem.simpleAbilityCheck);
        if (!passed) return false;
      }
      if (menuItem.items) {
        menuItem.items = filterMenuItemsByAccess(menuItem.items, userRole, grantedAbilities);
      }
      return true;
    });
  }
  FeatureMenuItemHelper.filterMenuItemsByAccess = filterMenuItemsByAccess;
})(FeatureMenuItemHelper || (FeatureMenuItemHelper = {}));