import { deleteDotProp } from './dot-prop';
import * as cloneDeep from 'lodash.clonedeep';
/**
 * recursively copy an object omitting properties as specified in paths list.
 *
 * paths examples:
 * creationDate
 * subobject.propName
 * arrayProp[].propName
 */
export function objectCloneOmit(obj, paths) {
  const clone = cloneDeep(obj);
  paths.forEach(path => {
    deleteDotProp(clone, path);
  });
  // eslint-disable-next-line guard-for-in
  // for (const property in obj) {
  // 	const value = obj[property];
  // 	if(paths.includes(property)) {
  // 		continue; // match, do not clone this.
  // 	} else if(isPrimitive(value)) {
  // 		clone[property] = value;
  // 	} else if(value instanceof Map ||value instanceof Set) {
  // 		throw new Error('Oops, Maps/Sets are not supported yet, please add if needed.')
  // 	} else if (Array.isArray(value)) {
  // 		const childPropsToOmit = paths
  // 			.filter(p=>p.startsWith(property+'[]'))
  // 			.map(p=>p.substring(property.length+3))
  // 		clone[property] = value.map((item) => objectCloneOmit(item, childPropsToOmit));
  // 		continue;
  // 	} else if (value !== null && typeof value === "object") {
  // 		const childPropsToOmit = paths
  // 			.filter(p=>p.startsWith(property))
  // 			.map(p=>p.substring(property.length+1))
  // 		clone[property] = objectCloneOmit(value, childPropsToOmit);
  // 		continue;
  // 	} else {
  // 		throw new Error('objectCloneOmit failed to clone property value: '+typeof value+' '+value)
  // 	}
  // }
  return clone;
}