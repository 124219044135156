import { MediaAssetType, MediaTransformationsRegistry } from '@common/media/shared';
import { ConfigService } from '@core/shared/config';
import { faFile, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { CloudinaryUrlParser } from './cloudinary-url-parser';
import { CloudinaryMediaAssetUrlBuilder } from './cloudinary-url.builder';
export class MediaAssetProviderCloudinary {
  constructor(injector, protocol) {
    this.injector = injector;
    this.protocol = protocol;
    this.name = 'Cloudinary';
    this.providerId = 'cloudinary';
    this.features = {
      clientUpload: false,
      chunkedUpload: true,
      checkForExistingFile: true,
      selectFromHost: true,
      transformationManager: true,
      removeFileOnAbortedUpload: false,
      uploadPathSuggestions: true,
      transformationsDuringUpload: true,
      checkForReadyness: false,
      notifyReadyness: true,
      dynamicMediaTransformations: true,
      openInHostUi: false
    };
    this.config = injector.get(ConfigService, {
      strict: false
    });
    this.urlParser = new CloudinaryUrlParser(this.config);
    this.mediaTransformations = injector.get(MediaTransformationsRegistry, {
      strict: false
    });
    this.initialize();
  }
  initialize() {}
  getPreviewInfo(asset, interactive, size) {
    if (!asset) throw new Error('getPreviewInfo called with a falsy asset!');
    if (!interactive && asset.type === MediaAssetType.video) {
      return this.createMediaPreviewTransformation(asset, this.getTransformsForPreviewSize(size));
    }
    if (asset.type === MediaAssetType.image) {
      return this.createMediaPreviewTransformation(asset, this.getTransformsForPreviewSize(size));
    }
    const format = this.getAssetFormat(asset);
    return {
      url: asset.directUrl,
      type: asset.type,
      format: format,
      icon: this.previewIconFor(format)
    };
  }
  getTransformationPreset(asset, presetName) {
    const transformation = this.mediaTransformations.getTransformation(asset.type, presetName);
    const transformed = this.getMediaTransformation(asset, transformation.transformation);
    return transformed;
  }
  getMediaTransformation(asset, transforms) {
    const urlBuilder = this.createUrlBuilder(asset);
    urlBuilder.setTransforms(transforms);
    return {
      url: urlBuilder.getUrl(),
      type: MediaAssetType.image
    };
  }
  getAssetFormat(asset) {
    if (asset.meta?.format) return asset.meta?.format;
    if (asset.providerData.originalExtension) return asset.providerData.originalExtension;
    return null;
  }
  createUrlBuilder(item) {
    return new CloudinaryMediaAssetUrlBuilder(item, this.urlParser, this.mediaTransformations);
  }
  createMediaPreviewTransformation(asset, transforms) {
    const urlBuilder = this.createUrlBuilder(asset);
    const fileFormat = urlBuilder.isVector ? 'svg' : 'jpg';
    urlBuilder.setTransforms(transforms);
    urlBuilder.setFileFormat(fileFormat);
    return {
      url: urlBuilder.getUrl(),
      type: MediaAssetType.image,
      format: fileFormat
    };
  }
  getTransformsForPreviewSize(size) {
    if (size === 'preview') {
      return {
        width: 1600
      };
    } else if (size === 'thumb') {
      return {
        width: 320
      };
    }
  }
  previewIconFor(fileFormat) {
    switch (fileFormat) {
      case 'pdf':
        return faFilePdf;
      case 'doc':
      case 'docx':
        return faFileWord;
    }
    return faFile;
  }
}