import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UserItem } from '@common/users/shared';
import { AccessState } from '@core/frontend-shared';
import { FeatureMenuItemService } from '@core/frontend-shared/feature';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
	selector: 'mpf-landingpage',
	templateUrl: './landingpage.component.html',
	styleUrls: ['./landingpage.component.scss'],
	animations:[
		trigger('OutAnimation', [
			transition(':leave', [
				style({ opacity: 1 }),
				animate('.9s ease-in', style({ opacity: 0 }))
			])
		]),
	],
	styles:[]
})
export class LandingpageComponent implements AfterViewInit {
	@Select(AccessState.loggedIn) loggedIn$:Observable<boolean>;
	@Select(AccessState.accessData('user')) userData$:Observable<UserItem>;
	@Select(AccessState.accessData('isManager')) isManager$:Observable<boolean>;

	hasManagerMenuItems$:Observable<boolean>;
	
	// @ViewChild('video',{static:false}) video:ElementRef;
	muteState = true;
	// videoVisible = true;

	constructor(
		featureMenuItemService:FeatureMenuItemService,
	) {
		this.hasManagerMenuItems$ = featureMenuItemService.hasAccessibleMenuItems('managementToolbar')
	}


	ngAfterViewInit() {
		// this.video.nativeElement.addEventListener('timeupdate', this.videoListener);
	}

	// videoListener = (event) => {
	// 	const progress = this.video.nativeElement.currentTime / this.video.nativeElement.duration;
	// 	if(progress>.95) {
	// 		this.video.nativeElement.removeEventListener('timeupdate', this.videoListener);
	// 		this.videoVisible = false;
	// 	}
	// }

	// toggleVideoMuteState() {
	// 	const video = this.video.nativeElement;
	// 	this.muteState = video.muted = !video.muted;
	// }

}
